import React from "react";
import styled from "styled-components";
import { Typography, Paper, Stack, Card, CardContent, CardMedia, IconButton, Divider } from "@mui/material";
import { Box } from "@mui/system";
import { FaGooglePlay, FaAppStoreIos, FaWindows } from 'react-icons/fa';

const Styles = styled.div`
`;

const Products = () => {
  return (
    <Styles>
        <Paper square elevation={2} sx={{display: 'flex', pl: .5}}>
            <Stack spacing={2} justifyContent="center" alignItems="center">
            <Typography variant="h5" color="text.primary" sx={{ mt: 1 }}>
                Products
            </Typography>
            <Stack spacing={2.25} justifyContent="center" alignItems="center">
                <Card sx={{ display: 'flex', background:'#E6F4F1', margin:2}}>
                    <CardMedia
                        component="img"
                        sx={{ minWidth: 5, maxWidth:125 , minHeight:5, maxHeight:125, margin:1, mt:3}}
                        image="/techgnosis_blue_logo_1024.png"
                        alt="Configure Me - Techgnosis Internal"/>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ }}>
                            <Typography component="div" variant="h6">
                                Configure Me
                            </Typography>
                            <Typography variant="subtitle1" color="text.secondary" component="div">
                                An internal support application. 
                            </Typography>
                            <Typography variant="body1" color="text.secondary" component="div">
                                Current Version: Beta 1. 
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', mt:2}}>
                                <Stack direction="row" spacing={2.25} justifyContent="center" alignItems="center" 
                                divider={<Divider orientation="vertical" flexItem />} sx={{ mt: .5 }}>
                                    <IconButton aria-label="Windows">
                                        <FaWindows />
                                    </IconButton>
                                    <IconButton aria-label="Android">
                                        <FaGooglePlay />
                                    </IconButton>
                                    <IconButton aria-label="Apple">
                                        <FaAppStoreIos />
                                    </IconButton>
                                </Stack>
                            </Box>
                        </CardContent>
                    </Box>
                </Card>
            </Stack>
            </Stack>
        </Paper>
    </Styles>
  );
};

export default Products;