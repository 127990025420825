import React from "react";
import styled from "styled-components";
import SendIcon from '@mui/icons-material/Send';
import { Typography, Paper, Stack, MenuItem, Select, InputLabel, FormControl, Box, TextField, Button } from "@mui/material";

const Styles = styled.div`
`;

const Contact = () => {
  const [reason, setReason] = React.useState('');
  const [subject, setSubject] = React.useState('');
  const [fromEmail, setFromEmail] = React.useState('');
  const [message, setMessage] = React.useState('');

  const handleSetReason = (event) => {
    setReason(event.target.value);
  };

  const handleSetSubject = (event) => {
    setSubject(event.target.value);
  };

  const handleSetFromEmail = (event) => {
    setFromEmail(event.target.value);
  };

  const handleSetMessage = (event) => {
    setMessage(event.target.value);
  };

  return (
    <Styles>
        <Paper square elevation={2} sx={{display: 'flex', pl: 2}}>
            <Stack spacing={2} margin={2} justifyContent="center" alignItems="center">
              <Typography variant="h5" align="center" color="text.primary" sx={{ mt: 1 }}>
                  Contact Us
              </Typography>
              <Box sx={{ minWidth: 300 , maxWidth: 500, margin:2}}>
                <Stack spacing={2.25} justifyContent="center" direction={"column"} alignItems="center">
                    <FormControl fullWidth>
                      <InputLabel id="reason-select-label">Contact Reason</InputLabel>
                      <Select
                        labelId="reason-select-label"
                        id="reason-select"
                        value={reason}
                        label="Contact Reason"
                        onChange={handleSetReason}
                      >
                        <MenuItem value={"General"}>General</MenuItem>
                        <MenuItem value={"Privacy"}>Privacy</MenuItem>
                        <MenuItem value={"Removal Request"}>Removal Request</MenuItem>
                        <MenuItem value={"App Support"}>App Support</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        id="outlined-from-email"
                        label="From Email"
                        defaultValue="Email Please"
                        value={fromEmail}
                        onChange={handleSetFromEmail}/>
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        id="outlined-helperText"
                        label="Subject"
                        defaultValue="Email Please"
                        value={subject}
                        onChange={handleSetSubject}/>
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        id="outlined-multiline-static"
                        label="Message"
                        multiline
                        rows={4}
                        defaultValue="Message"
                        value={message}
                        onChange={handleSetMessage}
                      />
                    </FormControl>
                </Stack>
                <Button variant="contained" endIcon={<SendIcon />} sx={{ mt:2}}>
                      Send
                </Button>                      
              </Box>
            </Stack>
        </Paper>
    </Styles>
  );
};

export default Contact;