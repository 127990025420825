import React from "react";
import styled from "styled-components";
import { Typography, BottomNavigation, Paper, Stack } from "@mui/material";
import { Box } from "@mui/system";

const Styles = styled.div`
.MuiBottomNavigation-root {
  background: ;
}
`;

const Footer = () => {
  return (
    <Styles>
      <Paper elevation={4}>
        <BottomNavigation>
          <Box sx={{ backgroundColor: '#6B75C7', flexGrow: 1 , alignContent: 'center' }}>
            <Stack>
              <Typography variant="body1" align="center" sx={{ mt:.5 , color: '#FFFF'}}>
                {'Copyright © Techgnosis Pty. Ltd. '}
                {new Date().getFullYear()}
                {'.'}
              </Typography>
              <Typography variant="body1" align="center" sx={{ mt:.1 , color: '#FFFF'}}>
                All Rights Reserved.
              </Typography>
            </Stack>
          </Box>
        </BottomNavigation>
      </Paper>
    </Styles>
  );
};

export default Footer;