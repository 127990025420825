import React from "react";
import styled from "styled-components";
import { Typography, Paper, Stack } from "@mui/material";

const Styles = styled.div`
`;

const Pricing = () => {
  return (
    <Styles>
        <Paper square elevation={2} sx={{display: 'flex', pl: 2}}>
            <Stack spacing={2} justifyContent="center" alignItems="center">
            <Typography variant="h5" align="center" color="text.primary" sx={{ mt: 1 }}>
                Pricing
            </Typography>
            <Stack spacing={2.25} justifyContent="center" alignItems="center">
                <Typography variant="h6" align="center" color="text.primary">
                </Typography>
                <Typography variant="h6" align="center" color="text.primary" sx={{ mt: 2 }}>
                </Typography>
                <Typography variant="h6" align="center" color="text.primary" sx={{ mt: 2 }}>
                </Typography>
            </Stack>
            </Stack>
        </Paper>
    </Styles>
  );
};

export default Pricing;