import styled from "styled-components";
import TechgnosisPP from './Documents/techgnosis-privacy.md';
import { React, useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'

const Styles = styled.div`
`;

const Privacy = () => {
    const [privacyPolicyText, setPrivacyPolicyText] = useState('')

	useEffect(() => {
		fetch(TechgnosisPP).then(res => res.text()).then(text => setPrivacyPolicyText(text))
	})

    return (
        <Styles>
            <ReactMarkdown children={privacyPolicyText} />
        </Styles>
      );        
};

export default Privacy;