import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Layout from './components/Layout';
import Home from './components/Home';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Products from './components/Products';
import Contact from './components/Contact';
import Pricing from './components/Pricing';
import Privacy from './components/Privacy';

export default function App() {
  return (
    <Container maxWidth="md">      
      <Box sx={{ my: 4, backgroundColor:'#EAFCFF'}}>
        <Navbar />
        <Layout>
          <Router>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path='/Contact' element={<Contact />} />
              <Route path='/Products' element={<Products />} />
              <Route path='/Pricing' element={<Pricing />} />
              <Route path='/Privacy' element={<Privacy />} />
            </Routes>
          </Router>
        </Layout>
        <Footer />
      </Box>
    </Container>
  );
}
