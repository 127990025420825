import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const MessagePanes = [
  {
    label: 'Security',
    imgPath:
      'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
    line1:'We take securing customer information seriously and have implemented policies and procedures to ensure confidentiality, integrity, and availability of data.',
    line2:'We adhere to the Australian Privacy Principles (APPs) and have implemented measures to protect against unauthorized access, disclosure, or misuse of personal information.',
    line3:'Our commitment to information security extends beyond compliance and is ingrained in our company culture, with regular training and audits to ensure ongoing vigilance and improvement.',
  },
  {
    label: 'Ease of Use',
    imgPath:
      'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
    line1:'We prioritise simplicity and ease of use in software design to provide a user-friendly experience for all customers.',
    line2:'Our software is designed to streamline workflows and automate repetitive tasks, with clear instructions and intuitive interfaces for beginner and advanced users.',
    line3:'We provide extensive documentation and support resources to help customers get the most out of our products, ensuring accessibility and ease of use for all users.',
    },
  {
    label: 'Support',
    imgPath:
      'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
    line1:'Techgnosis is committed to providing exceptional customer support to ensure customer satisfaction and success.',
    line2:'Our knowledgeable and friendly support team is available to assist with installation, configuration, troubleshooting, and any other questions or concerns customers may have.',
    line3:'We also offer self-help resources such as a knowledge base and community forum to help customers find answers quickly and easily.',
    },
];

function SwipeableStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = MessagePanes.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ mt:1, maxWidth: 410, flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          pl: 2,
          bgcolor: 'background.default',
        }}
      >
        <Typography variant="h6">{MessagePanes[activeStep].label}</Typography>
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {MessagePanes.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Paper
              square
              elevation={2}
              sx={{
                display: 'flex',
                alignItems: 'justify',
                height: 350,
                pl: 2,
                bgcolor: 'background.default',
              }}
            >
              <Stack spacing={2}>
                <Typography>{MessagePanes[activeStep].line1}</Typography>
                <Typography>{MessagePanes[activeStep].line2}</Typography>                  
                <Typography>{MessagePanes[activeStep].line3}</Typography>
              </Stack>
            </Paper>      
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  );
}

export default SwipeableStepper;