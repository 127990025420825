import React from "react";
import styled from "styled-components";
import { Typography, Grid, Divider, Paper, Stack } from "@mui/material";
import { Box } from "@mui/system";
import SwipeableStepper from "./SwipeableStepper";

const Styles = styled.div`
`;

const Home = () => {
  return (
    <Styles>
      <Grid container columnSpacing={1}>
        <Grid item xs={12} sm={6} md={6}>
          <Box component="img"
            sx={{alignContent:'center', display: 'block', maxWidth: 400, overflow: 'hidden', width: '100%', background: '#FFFF', mt:1}}
            src="./techgnosis_blue_logo.png"/>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box sx={{ maxWidth: 400, flexGrow: 1, mt:1 }}>
            <Paper square elevation={0} sx={{display: 'flex', pl: 1}}>
              <Stack spacing={2} justifyContent="center" alignItems="center">
                <Typography variant="h5" align="center" justifyContent="center" color="text.primary" sx={{ mt: 1 }}>
                  Cross-Platform App Specialists.
                </Typography>
                <Stack direction="row" spacing={2.25} justifyContent="center" alignItems="center" 
                  divider={<Divider orientation="vertical" flexItem />} sx={{ mt: 2 }}>
                  <Typography variant="h6" align="center" color="text.primary">
                    Consulting
                  </Typography>
                  <Typography variant="h6" align="center" color="text.primary" sx={{ mt: 2 }}>
                    Delivery
                  </Typography>
                  <Typography variant="h6" align="center" color="text.primary" sx={{ mt: 2 }}>
                    Management
                  </Typography>
                </Stack>
              </Stack>
            </Paper>
          </Box>
          <SwipeableStepper/>
        </Grid>
      </Grid>
    </Styles>
  );
};

export default Home;