import React from "react";
import Container from '@mui/material/Container';
import { Box, Paper } from "@mui/material";
import styled from "styled-components";
import { Style } from "@mui/icons-material";

const Styles = styled.div`
`;

const Layout = (props) => {
  return (
    <Styles>
      <Container maxWidth="xl">
        <Box sx={{mb:1, mt:1, width:'100%'}}>
          {props.children}
        </Box>
      </Container>
    </Styles>
    );
};

export default Layout;